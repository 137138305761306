import Turbolinks from "turbolinks"
Turbolinks.start()
import Rails from '@rails/ujs';
Rails.start();

import './location';
import './settings';
import './incrementable';

import "@fortawesome/fontawesome-free/js/all";
import '../images/favicon.ico';

import './menu/inventory_item';
import './menu/tag_input_listener';
import './menu/calorie_inputs';

import './clickable.jquery';
import './sortable.jquery'

// Workaround to ensure tokens are refreshed for form submission.
import 'jquery';
$( document ).on('turbolinks:load', function() {
  $.rails.refreshCSRFTokens();
})
